export const toAbsoluteUrl = (pathname) => {
	if (
		window.location.origin !== process.env.REACT_APP_URL &&
		process.env.REACT_APP_CONTAINER_APP_URL
	)
		return (
			process.env.REACT_APP_CONTAINER_APP_URL +
			'/' +
			process.env.REACT_APP_NAME +
			pathname
		)
	else
		return (
			process.env.REACT_APP_URL + '/' + process.env.REACT_APP_NAME + pathname
		)
}
