import { login_using_refresh_token } from '../app/modules/Auth/_redux/authCrud'
import * as auth from '../app/modules/Auth/_redux/authRedux'

export default function setupAxios(axios, store) {
	axios.interceptors.request.use(
		(config) => {
			const {
				auth: { authToken },
			} = store.getState()

			if (authToken) {
				config.headers.Authorization = `Bearer ${authToken}`
			}

			return config
		},
		(err) => Promise.reject(err)
	)
	axios.interceptors.response.use(
		(response) => {
			return response
		},
		async (error) => {
			let {
				auth: { refreshToken },
			} = store.getState()
			const originalRequest = error.config
			if (
				error.response.status === 403 &&
				refreshToken &&
				!originalRequest._retry
			) {
				try {
					originalRequest._retry = true
					const splashScreen = document.getElementById('splash-screen')
					if (splashScreen) splashScreen.style.display = ''
					const response = await login_using_refresh_token({
						refresh_token: refreshToken,
					})
					if (splashScreen) splashScreen.style.display = 'none'
					if (response?.data?.status) {
						//we have token in this response
						let authToken = response.data.token
						let refreshToken = response.data.refresh_token
						let user = response.data.user

						//save this token
						store.dispatch(auth.actions.login(authToken, refreshToken, user))
						return axios(originalRequest)
					} else {
						store.dispatch(auth.actions.logout())
					}
				} catch (error) {
					store.dispatch(auth.actions.logout())
				}
			} else if (error.response.status === 401) {
				store.dispatch(auth.actions.logout())
			} else {
				return error
			}
		}
	)
}
