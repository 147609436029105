import { all } from 'redux-saga/effects'
import { combineReducers } from 'redux'

import * as auth from '../app/modules/Auth/_redux/authRedux'
import { pagesSlice } from '../app/modules/MyPages/_redux/pagesSlice'
import { customersSlice } from '../app/modules/Customer/_redux/customersSlice'

export const rootReducer = combineReducers({
	auth: auth.reducer,
	pages: pagesSlice.reducer,
	customers: customersSlice.reducer,
})

export function* rootSaga() {
	yield all([auth.saga()])
}
