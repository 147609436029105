import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
// import { put, takeLatest } from 'redux-saga/effects';

export const actionTypes = {
	Login: '[Login] Action',
	Logout: '[Logout] Action',
	UserLoaded: '[Load User] Auth API',
	TokenLoaded: '[Load Token] Auth API',
}

const initialAuthState = {
	user: undefined,
	authToken: undefined,
	refreshToken: undefined,
}

export const reducer = persistReducer(
	{
		storage,
		key: process.env.REACT_APP_LOCAL_STORAGE_VARIABLE,
		whitelist: ['user', 'microsolution', 'authToken', 'refreshToken'],
	},
	(state = initialAuthState, action) => {
		switch (action.type) {
			case actionTypes.Login: {
				const { authToken, refreshToken, user } = action.payload

				return { authToken, refreshToken, user }
			}

			case actionTypes.Logout: {
				return initialAuthState
			}

			case actionTypes.UserLoaded: {
				const { user } = action.payload
				return { ...state, user }
			}

			case actionTypes.TokenLoaded: {
				const { authToken, refreshToken } = action.payload
				return { ...state, authToken, refreshToken }
			}

			default:
				return state
		}
	},
)

export const actions = {
	login: (authToken, refreshToken, user) => ({
		type: actionTypes.Login,
		payload: { authToken, refreshToken, user },
	}),
	logout: () => ({ type: actionTypes.Logout }),
	// requestUser: (user) => ({
	//   type: actionTypes.UserRequested,
	//   payload: { user },
	// }),
	fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
	fulfillToken: (authToken, refreshToken) => ({
		type: actionTypes.TokenLoaded,
		payload: { authToken, refreshToken },
	}),
}

export function* saga() {
	//   yield takeLatest(actionTypes.Login, function* loginSaga() {
	//     yield put(actions.requestUser());
	//   });
	//   yield takeLatest(actionTypes.Register, function* registerSaga() {
	//     yield put(actions.requestUser());
	//   });
	//   yield takeLatest(actionTypes.UserRequested, function* userRequested() {
	//     //Implemnted login with user module so don't need this
	//     // const { data: user } = yield getUserByToken();
	//     // yield put(actions.fulfillUser(user));
	//   });
}
